import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { scrollToTop } from '@/functions'
import { TokenHelper } from './TokenHelper'
import { eventbus } from '@/main'
import { User } from './User'
import { Storage } from '@/Storage'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // other
    {
      name: 'what-new',
      path: '/what-new',
      component: () => import('./views/other/ViewWhatNew.vue'),
      meta: {
        requiresSecondStageToken: true
      }
    },
    // home
    {
      name: 'home',
      path: '/home',
      component: () => import('./views/home/list/ViewHomeList.vue'),
      meta: {
        requiresAnyCustomerRole: ['master_administrator'],
        requiresRoleOrPermissions: true,
        requiresAnyPermission: [
          'event_coordinator',
          'infrastructure_manager',
          'learning_experience_administrator',
          'user_administrator',
          'course_creator',
          'course_licence_manager',
          'manager'
        ],
        requiresFirstStageToken: true,
        requiresSecondStageToken: true
      }
    },
    // catalogue
    {
      name: 'catalogue',
      path: '/catalogue',
      component: () => import('./views/catalogue/ViewCatalogueMain.vue'),
      redirect: () => {
        const nav = [
          {
            name: 'ebs-course-list',
            permissions: ['course_creator', 'course_licence_manager', 'learner', 'learning_experience_administrator', 'manager'],
            services: ['CCS', 'LMS']
          },
          {
            name: 'ebs-event-list',
            customerRoles: ['trainer', 'master_administrator'],
            permissions: ['infrastructure_manager', 'event_coordinator', 'manager'],
            services: ['EBS']
          },
          {
            name: 'ebs-module-list',
            permissions: ['course_creator'],
            services: ['CCS']
          },
          {
            name: 'ebs-licence-list',
            permissions: ['course_licence_manager', 'course_creator'],
            services: ['CCS']
          },
          {
            name: 'ebs-requests-list',
            permissions: ['learning_experience_administrator', 'manager']
          }
        ]
        return performNavRedirect(nav)
      },
      children: [
        // courses
        {
          path: '/catalogue/courses',
          name: 'ebs-course-list',
          component: () => import('./views/ebs/courses/list/ViewCourseList.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresAnyCustomerService: ['CCS', 'LMS'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['admin', 'course_creator', 'course_licence_manager', 'learner', 'learning_experience_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/courses/:id/show/admin',
          name: 'ebs-course-show-admin',
          component: () => import('./views/ebs/courses/show/ViewCourseShow.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS', 'LMS'],
            requiresAnyPermission: ['admin', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        //
        {
          path: '/my-learning/dashboard/browse/:courseId/:versionId/:courseCatalogId/show',
          name: 'ebs-course-enrolment-show-browse',
          component: () => import('./views/ebs/courses/show/ViewCourseShow.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS', 'LMS'],
            requiresAnyPermission: ['course_creator', 'course_licence_manager', 'learner', 'learning_experience_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'my-learning'
          }
        },
        {
          path: '/my-learning/dashboard/browse/event/:id/show/single',
          name: 'ebs-course-enrolment-show-browse-event-show',
          component: () => import('./views/ebs/events/show/ViewEventShow.vue'),
          meta: {
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['learning_experience_administrator', 'event_coordinator', 'infrastructure_manager', 'learner', 'course_creator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'my-learning'
          }
        },
        {
          path: '/my-learning/dashboard/browse/event/:eventId/session/:sessionId/show/single',
          name: 'ebs-course-enrolment-show-browse-session-show',
          component: () => import('./views/ebs/sessions/show/ViewSessionShow.vue'),
          meta: {
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'infrastructure_manager', 'learner', 'course_creator', 'learning_experience_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'my-learning'
          }
        },
        //
        {
          path: '/my-learning/dashboard/courses/:courseId/:versionId/:courseCatalogId/:enrolmentId?/show',
          name: 'ebs-course-enrolment-show-courses',
          component: () => import('./views/ebs/courses/show/ViewCourseShow.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS', 'LMS'],
            requiresAnyPermission: ['course_creator', 'course_licence_manager', 'learner', 'learning_experience_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'my-learning'
          }
        },
        {
          path: '/my-learning/dashboard/courses/event/:id/show/single',
          name: 'ebs-course-enrolment-show-courses-event-show',
          component: () => import('./views/ebs/events/show/ViewEventShow.vue'),
          meta: {
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['learning_experience_administrator', 'event_coordinator', 'infrastructure_manager', 'learner', 'course_creator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'my-learning'
          }
        },
        {
          path: '/my-learning/dashboard/courses/event/:eventId/session/:sessionId/show/single',
          name: 'ebs-course-enrolment-show-courses-session-show',
          component: () => import('./views/ebs/sessions/show/ViewSessionShow.vue'),
          meta: {
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'infrastructure_manager', 'learner', 'course_creator', 'learning_experience_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'my-learning'
          }
        },
        //
        {
          path: '/my-learning/dashboard/completions/:courseId/:versionId/:courseCatalogId/:enrolmentId/show',
          name: 'ebs-course-enrolment-show-completions',
          component: () => import('./views/ebs/courses/show/ViewCourseShow.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS', 'LMS'],
            requiresAnyPermission: ['course_creator', 'course_licence_manager', 'learner', 'learning_experience_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'my-learning'
          }
        },
        {
          path: '/my-learning/dashboard/completions/event/:id/show/single',
          name: 'ebs-course-enrolment-show-completions-event-show',
          component: () => import('./views/ebs/events/show/ViewEventShow.vue'),
          meta: {
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['learning_experience_administrator', 'event_coordinator', 'infrastructure_manager', 'learner', 'course_creator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'my-learning'
          }
        },
        {
          path: '/my-learning/dashboard/completions/event/:eventId/session/:sessionId/show/single',
          name: 'ebs-course-enrolment-show-completions-session-show',
          component: () => import('./views/ebs/sessions/show/ViewSessionShow.vue'),
          meta: {
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'infrastructure_manager', 'learner', 'course_creator', 'learning_experience_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'my-learning'
          }
        },
        //
        {
          path: '/catalogue/courses/add',
          name: 'ebs-course-add',
          redirect: { name: 'ebs-course-add-details' },
          component: () => import('./views/ebs/courses/add/ViewCourseAdd.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS', 'LMS'],
            requiresAnyPermission: ['course_creator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          children: [
            {
              name: 'ebs-course-add-details',
              path: 'details',
              component: () => import('./views/ebs/courses/add/ViewCourseAddDetails.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-course-add-modules',
              path: 'modules',
              component: () => import('./views/ebs/courses/add/ViewCourseAddModules.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-course-add-enrolment',
              path: 'enrolment',
              component: () => import('./views/ebs/courses/add/ViewCourseAddEnrolment.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-course-add-certificate',
              path: 'certificate',
              component: () => import('./views/ebs/courses/add/ViewCourseAddCertificate.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-course-add-attachments',
              path: 'attachments',
              component: () => import('./views/ebs/courses/add/ViewCourseAddAttachments.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-course-add-links',
              path: 'links',
              component: () => import('./views/ebs/courses/add/ViewCourseAddLinks.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-course-add-versions',
              path: 'versions',
              component: () => import('./views/ebs/courses/add/ViewCourseAddVersions.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            }
          ]
        },
        {
          path: '/catalogue/courses/:id/edit/course',
          name: 'ebs-course-edit',
          redirect: { name: 'ebs-course-edit-details' },
          component: () => import('./views/ebs/courses/edit/ViewCourseEdit.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyCustomerService: ['CCS', 'LMS'],
            requiresAnyPermission: ['admin', 'course_creator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          children: [
            {
              name: 'ebs-course-edit-details',
              path: 'details',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditDetails.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['admin', 'course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue',
                parent: 'ebs-course-edit'
              }
            },
            {
              name: 'ebs-course-edit-modules',
              path: 'modules',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditModules.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['admin', 'course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue',
                parent: 'ebs-course-edit'
              }
            },
            {
              name: 'ebs-course-edit-enrolment',
              path: 'enrolment',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditEnrolment.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue',
                parent: 'ebs-course-edit'
              }
            },
            {
              name: 'ebs-course-edit-certificate',
              path: 'certificate',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditCertificate.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['admin', 'course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue',
                parent: 'ebs-course-edit'
              }
            },
            {
              name: 'ebs-course-edit-attachments',
              path: 'attachments',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditAttachments.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['admin', 'course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue',
                parent: 'ebs-course-edit'
              }
            },
            {
              name: 'ebs-course-edit-links',
              path: 'links',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditLinks.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['admin', 'course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue',
                parent: 'ebs-course-edit'
              }
            },
            {
              name: 'ebs-course-edit-feedback',
              path: 'feedback',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditFeedbackForm.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['admin', 'course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue',
                parent: 'ebs-course-edit'
              }
            },
            {
              name: 'ebs-course-edit-notifications',
              path: 'notifications',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditNotifications.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['admin', 'course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue',
                parent: 'ebs-course-edit'
              }
            },
            {
              name: 'ebs-course-edit-versions',
              path: 'versions',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditVersions.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyCustomerService: ['CCS', 'LMS'],
                requiresAnyPermission: ['admin', 'course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue',
                parent: 'ebs-course-edit'
              }
            }
          ]
        },
        // catalog item
        {
          path: '/catalogue/courses/:courseId/:catalogItemId/:versionId/edit',
          name: 'ebs-catalog-item-edit',
          redirect: { name: 'ebs-catalog-item-edit-details' },
          component: () => import('./views/ebs/courses/edit/ViewCourseEdit.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS'],
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          children: [
            {
              name: 'ebs-catalog-item-edit-details',
              path: 'details',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditDetails.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-catalog-item-edit-modules',
              path: 'modules',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditModules.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-catalog-item-edit-enrolment',
              path: 'enrolment',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditEnrolment.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-catalog-item-edit-certificate',
              path: 'certificate',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditCertificate.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-catalog-item-edit-feedback',
              path: 'feedback',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditFeedbackForm.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-catalog-item-edit-attachments',
              path: 'attachments',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditAttachments.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-catalog-item-edit-notifications',
              path: 'notifications',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditNotifications.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-catalog-item-edit-links',
              path: 'links',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditLinks.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-catalog-item-edit-versions',
              path: 'versions',
              component: () => import('./views/ebs/courses/edit/ViewCourseEditVersions.vue'),
              meta: {
                requiresAnyCustomerService: ['LMS'],
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            }
          ]
        },
        {
          name: 'ebs-catalog-item-edit-feedback-forms-add',
          path: '/catalogue/courses/:courseId/:catalogId/:versionId/edit/feedback/add',
          component: () => import('./views/ebs/courses/edit/ViewCourseEditFeedbackFormAdd.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        // requests
        {
          path: '/catalogue/requests',
          name: 'ebs-requests-list',
          component: () => import('./views/ebs/requests/list/ViewRequestsList.vue'),
          meta: {
            requiresAnyPermission: ['learning_experience_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        // events
        {
          path: '/catalogue/events',
          name: 'ebs-event-list',
          component: () => import('./views/ebs/events/list/ViewEventList.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['learning_experience_administrator', 'event_coordinator', 'infrastructure_manager', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/events-course-assign/:courseId',
          name: 'ebs-event-list-course-assign',
          component: () => import('./views/ebs/events/list/ViewEventListSelectUpdate.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/courses/event/:id/show/single',
          name: 'ebs-event-show',
          component: () => import('./views/ebs/events/show/ViewEventShow.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['learning_experience_administrator', 'event_coordinator', 'infrastructure_manager', 'learner', 'course_creator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/courses/event/add',
          name: 'ebs-event-add',
          redirect: { name: 'ebs-event-add-details' },
          component: () => import('./views/ebs/events/add/ViewEventAdd.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          children: [
            {
              name: 'ebs-event-add-details',
              path: 'details',
              component: () => import('./views/ebs/events/edit/ViewEventEditDetails.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-event-add-sessions',
              path: 'sessions',
              component: () => import('./views/ebs/events/edit/ViewEventEditSessions.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-event-add-bookings',
              path: 'bookings',
              component: () => import('./views/ebs/events/edit/ViewEventEditBookings.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-event-add-cancellations',
              path: 'cancellations',
              component: () => import('./views/ebs/events/edit/ViewEventEditCancellations.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-event-add-completions',
              path: 'completions',
              component: () => import('./views/ebs/events/edit/ViewEventEditCompletions.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-event-add-costs',
              path: 'costs',
              component: () => import('./views/ebs/events/edit/ViewEventEditCosts.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            }
          ]
        },
        {
          path: '/catalogue/courses/event/:id/edit',
          name: 'ebs-event-edit',
          redirect: { name: 'ebs-event-edit-details' },
          component: () => import('./views/ebs/events/edit/ViewEventEdit.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          children: [
            {
              name: 'ebs-event-edit-details',
              path: 'details',
              component: () => import('./views/ebs/events/edit/ViewEventEditDetails.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-event-edit-sessions',
              path: 'sessions',
              component: () => import('./views/ebs/events/edit/ViewEventEditSessions.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-event-edit-bookings',
              path: 'bookings',
              component: () => import('./views/ebs/events/edit/ViewEventEditBookings.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-event-edit-cancellations',
              path: 'cancellations',
              component: () => import('./views/ebs/events/edit/ViewEventEditCancellations.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-event-edit-completions',
              path: 'completions',
              component: () => import('./views/ebs/events/edit/ViewEventEditCompletions.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-event-edit-costs',
              path: 'costs',
              component: () => import('./views/ebs/events/edit/ViewEventEditCosts.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            }
          ]
        },
        // modules
        {
          path: '/catalogue/modules',
          name: 'ebs-module-list',
          component: () => import('./views/ebs/modules/list/ViewModuleList.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS'],
            requiresAnyPermission: ['course_creator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/modules/:id/show',
          name: 'ebs-module-show',
          redirect: { name: 'ebs-module-show-content' },
          component: () => import('./views/ebs/modules/show/ViewModuleShow.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS'],
            requiresAnyPermission: ['course_creator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          children: [
            {
              name: 'ebs-module-show-content',
              path: 'content',
              component: () => import('./views/ebs/modules/show/ViewModuleShowContent.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-module-show-details',
              path: 'details',
              component: () => import('./views/ebs/modules/show/ViewModuleShowDetails.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-module-show-versions',
              path: 'versions',
              component: () => import('./views/ebs/modules/show/ViewModuleShowVersions.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            }
          ]
        },
        {
          path: '/catalogue/modules/add',
          name: 'ebs-module-add',
          redirect: { name: 'ebs-module-add-content' },
          component: () => import('./views/ebs/modules/add/ViewModuleAdd.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS'],
            requiresAnyPermission: ['course_creator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          children: [
            {
              name: 'ebs-module-add-content',
              path: 'content',
              component: () => import('./views/ebs/modules/add/ViewModuleAddContent.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-module-add-details',
              path: 'details',
              component: () => import('./views/ebs/modules/add/ViewModuleAddDetails.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-module-add-versions',
              path: 'versions',
              component: () => import('./views/ebs/modules/add/ViewModuleAddVersions.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            }
          ]
        },
        {
          path: '/catalogue/modules/:id/edit',
          name: 'ebs-module-edit',
          redirect: { name: 'ebs-module-edit-content' },
          component: () => import('./views/ebs/modules/edit/ViewModuleEdit.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS'],
            requiresAnyPermission: ['course_creator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          children: [
            {
              name: 'ebs-module-edit-content',
              path: 'content',
              component: () => import('./views/ebs/modules/edit/ViewModuleEditContent.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-module-edit-details',
              path: 'details',
              component: () => import('./views/ebs/modules/edit/ViewModuleEditDetails.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-module-edit-versions',
              path: 'versions',
              component: () => import('./views/ebs/modules/edit/ViewModuleEditVersions.vue'),
              meta: {
                requiresAnyCustomerService: ['CCS'],
                requiresAnyPermission: ['course_creator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            }
          ]
        },
        {
          path: '/catalogue/modules/iframe/scorm/:id',
          name: 'ebs-module-iframe-scorm',
          component: () => import('./views/ebs/modules/iframe/ViewModuleIframeScorm.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresSecondStageToken: true,
            hiddenHeader: true,
            hiddenFooter: true
          }
        },
        // licence
        {
          path: '/catalogue/licences',
          name: 'ebs-licence-list',
          component: () => import('./views/ebs/licences/list/ViewLicenceList.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS'],
            requiresAnyPermission: ['course_licence_manager', 'course_creator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/licences/:offerId/:contractId/show',
          name: 'ebs-licence-show',
          component: () => import('./views/ebs/licences/show/ViewLicenceShow.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS'],
            requiresAnyPermission: ['course_licence_manager', 'course_creator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/licences/add',
          name: 'ebs-licence-add',
          component: () => import('./views/ebs/licences/add/ViewLicenceAdd.vue'),
          meta: {
            requiresAnyCustomerService: ['CCS'],
            requiresAnyPermission: ['course_licence_manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        // session
        {
          path: '/catalogue/courses/event/:eventId/session/:sessionId/show/single',
          name: 'ebs-session-show',
          component: () => import('./views/ebs/sessions/show/ViewSessionShow.vue'),
          meta: {
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'infrastructure_manager', 'learner', 'course_creator', 'learning_experience_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/courses/event/:eventId/session/add',
          name: 'ebs-session-add',
          redirect: { name: 'ebs-session-add-details' },
          component: () => import('./views/ebs/sessions/add/ViewSessionAdd.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          children: [
            {
              name: 'ebs-session-add-details',
              path: 'details',
              component: () => import('./views/ebs/sessions/edit/ViewSessionEditDetails.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-session-add-bookings',
              path: 'bookings',
              component: () => import('./views/ebs/sessions/edit/ViewSessionEditBookings.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-session-add-cancellations',
              path: 'cancellations',
              component: () => import('./views/ebs/sessions/edit/ViewSessionEditCancellations.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-session-add-completions',
              path: 'completions',
              component: () => import('./views/ebs/sessions/edit/ViewSessionEditCompletions.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-session-add-costs',
              path: 'costs',
              component: () => import('./views/ebs/sessions/edit/ViewSessionEditCosts.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            }
          ]
        },
        {
          path: '/catalogue/courses/event/:eventId/session/:sessionId/edit',
          name: 'ebs-session-edit',
          redirect: { name: 'ebs-session-edit-details' },
          component: () => import('./views/ebs/sessions/edit/ViewSessionEdit.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          children: [
            {
              name: 'ebs-session-edit-details',
              path: 'details',
              component: () => import('./views/ebs/sessions/edit/ViewSessionEditDetails.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-session-edit-bookings',
              path: 'bookings',
              component: () => import('./views/ebs/sessions/edit/ViewSessionEditBookings.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-session-edit-cancellations',
              path: 'cancellations',
              component: () => import('./views/ebs/sessions/edit/ViewSessionEditCancellations.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-session-edit-completions',
              path: 'completions',
              component: () => import('./views/ebs/sessions/edit/ViewSessionEditCompletions.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            },
            {
              name: 'ebs-session-edit-costs',
              path: 'costs',
              component: () => import('./views/ebs/sessions/edit/ViewSessionEditCosts.vue'),
              meta: {
                requiresAnyCustomerService: ['EBS'],
                requiresAnyPermission: ['event_coordinator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'catalogue'
              }
            }
          ]
        },
        {
          path: '/catalogue/courses/event/:eventId/session/:sessionId/register/:registerId',
          name: 'ebs-session-register',
          props: true,
          component: () => import('./views/ebs/sessions/register/ViewSessionRegister.vue'),
          meta: {
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/courses/event/:eventId/session/:sessionId/register/:registerId/print',
          name: 'ebs-session-register-print',
          props: true,
          component: () => import('./views/ebs/sessions/register/ViewSessionRegisterPrint.vue'),
          meta: {
            print: true,
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/courses/event/:eventId/session/:sessionId/summary',
          name: 'ebs-session-summary',
          component: () => import('./views/ebs/sessions/summary/ViewSessionSummary.vue'),
          meta: {
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          }
        },
        {
          path: '/catalogue/courses/event/:eventId/session/:sessionId/summary/print',
          name: 'ebs-session-summary-print',
          meta: {
            print: true,
            requiresAnyCustomerRole: ['trainer', 'master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'catalogue'
          },
          component: () => import('./views/ebs/sessions/summary/ViewSessionSummaryPrint.vue')
        },
        {
          path: '/catalogue/events/:eventId/session/:sessionId/iframe/booking',
          name: 'ebs-session-iframe-booking',
          component: () => import('./views/ebs/sessions/iframe/ViewSessionIframeBookingForm.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresSecondStageToken: true,
            hiddenHeader: true,
            hiddenFooter: true
          }
        }
      ]
    },
    // users
    {
      name: 'users',
      path: '/users',
      component: () => import('./views/users/ViewUsersMain.vue'),
      redirect: () => {
        const nav = [
          {
            name: 'lms-superadmin-user-list',
            roles: ['superadmin'],
            customerRoles: ['master_administrator'],
            permissions: ['user_administrator', 'manager']
          },
          {
            name: 'lms-superadmin-org-units-edit',
            roles: ['superadmin'],
            customerRoles: ['master_administrator'],
            permissions: ['user_administrator']
          },
          {
            name: 'lms-superadmin-groups-edit',
            roles: ['superadmin'],
            customerRoles: ['master_administrator'],
            permissions: ['user_administrator']
          },
          {
            name: 'ebs-trainer-list',
            customerRoles: ['master_administrator']
          },
          {
            name: 'ebs-autoenrolments-list',
            permissions: ['learning_experience_administrator'],
            services: ['LMS']
          },
          {
            name: 'ebs-workflows-list',
            permissions: ['learning_experience_administrator'],
            services: ['LMS']
          }
        ]
        return performNavRedirect(nav)
      },
      children: [
        // users
        {
          path: '/users/users',
          name: 'lms-superadmin-user-list',
          component: () => import('./views/lms/superadmin/users/list/ViewLmsSuperadminUsersList.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyPermission: ['user_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/users/:userId/edit',
          name: 'lms-superadmin-user-edit',
          component: () => import('./views/lms/superadmin/users/edit/ViewLmsSuperadminUsersEdit.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyPermission: ['user_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/users/:userId/:workspaceId?/learning-history',
          name: 'lms-superadmin-user-learning-history',
          component: () => import('./views/lms/superadmin/users/profile/edit/ViewLmsSuperadminUsersProfileEditLearningHistory.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyPermission: ['user_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/users/profile/:userId/:profileId/edit',
          name: 'lms-superadmin-user-profile-edit',
          redirect: { name: 'lms-superadmin-user-profile-edit-details' },
          component: () => import('./views/lms/superadmin/users/profile/edit/ViewLmsSuperadminUsersProfileEdit.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          },
          children: [
            {
              name: 'lms-superadmin-user-profile-edit-details',
              path: 'details',
              component: () => import('./views/lms/superadmin/users/profile/edit/ViewLmsSuperadminUsersProfileEditDetails.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyPermission: ['user_administrator', 'manager'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-profile-edit-org-unit-groups',
              path: 'org-unit-groups',
              component: () => import('./views/lms/superadmin/users/profile/edit/ViewLmsSuperadminUsersProfileEditOrgUnitGroups.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyPermission: ['user_administrator', 'manager'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-profile-edit-activity-log',
              path: 'activity-log',
              component: () => import('./views/lms/superadmin/users/profile/edit/ViewLmsSuperadminUsersProfileEditActivityLog.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            }
          ]
        },
        {
          path: '/users/users/profile/add',
          name: 'lms-superadmin-user-profile-add',
          component: () => import('./views/lms/superadmin/users/profile/add/ViewLmsSuperadminUsersProfileAdd.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyPermission: ['user_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          }
        },
        {
          name: 'lms-superadmin-user-bulk-add',
          path: '/users/users/bulk/add',
          component: () => import('./views/lms/superadmin/users/bulk/add/ViewLmsSuperadminUsersBulkAdd.vue'),
          redirect: () => {
            return { name: 'lms-superadmin-user-bulk-add-upload' }
          },
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          },
          children: [
            {
              name: 'lms-superadmin-user-bulk-add-upload',
              path: 'upload',
              component: () => import('./views/lms/superadmin/users/bulk/add/ViewLmsSuperadminUsersBulkAddUpload.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-add-roles',
              path: 'roles',
              component: () => import('./views/lms/superadmin/users/bulk/add/ViewLmsSuperadminUsersBulkAddRoles.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-add-preview',
              path: 'preview',
              component: () => import('./views/lms/superadmin/users/bulk/add/ViewLmsSuperadminUsersBulkAddPreview.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            }
          ]
        },
        {
          name: 'lms-superadmin-user-bulk-actions',
          path: '/users/users/bulk/actions',
          component: () => import('./views/lms/superadmin/users/bulk/actions/ViewLmsSuperadminUsersBulkActions.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          }
        },
        {
          name: 'lms-superadmin-user-bulk-actions-add-to-groups',
          path: '/users/users/bulk/add-to-groups',
          component: () => import('./views/lms/superadmin/users/bulk/actions/addtogroups/ViewLmsSuperadminUsersBulkActionsAddToGroups.vue'),
          redirect: () => {
            return { name: 'lms-superadmin-user-bulk-actions-add-to-groups-select-users' }
          },
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          },
          children: [
            {
              name: 'lms-superadmin-user-bulk-actions-add-to-groups-select-users',
              path: 'select-users',
              component: () => import('./views/lms/superadmin/users/bulk/actions/addtogroups/ViewLmsSuperadminUsersBulkActionsAddToGroupsSelectUsers.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-add-to-groups-preview',
              path: 'preview',
              component: () => import('./views/lms/superadmin/users/bulk/actions/addtogroups/ViewLmsSuperadminUsersBulkActionsAddToGroupsPreview.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-add-to-groups-select-groups',
              path: 'select-groups',
              component: () => import('./views/lms/superadmin/users/bulk/actions/addtogroups/ViewLmsSuperadminUsersBulkActionsAddToGroupsSelectGroups.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-add-to-groups-summary',
              path: 'summary',
              component: () => import('./views/lms/superadmin/users/bulk/actions/addtogroups/ViewLmsSuperadminUsersBulkActionsAddToGroupsSummary.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            }
          ]
        },
        {
          name: 'lms-superadmin-user-bulk-actions-remove-from-groups',
          path: '/users/users/bulk/remove-from-groups',
          component: () => import('./views/lms/superadmin/users/bulk/actions/removefromgroups/ViewLmsSuperadminUsersBulkActionsRemoveFromGroups.vue'),
          redirect: () => {
            return { name: 'lms-superadmin-user-bulk-actions-remove-from-groups-select-groups' }
          },
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          },
          children: [
            {
              name: 'lms-superadmin-user-bulk-actions-remove-from-groups-select-groups',
              path: 'select-groups',
              component: () => import('./views/lms/superadmin/users/bulk/actions/removefromgroups/ViewLmsSuperadminUsersBulkActionsRemoveFromGroupsSelectGroups.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-remove-from-groups-select-users',
              path: 'select-users',
              component: () => import('./views/lms/superadmin/users/bulk/actions/removefromgroups/ViewLmsSuperadminUsersBulkActionsRemoveFromGroupsSelectUsers.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-remove-from-groups-preview',
              path: 'preview',
              component: () => import('./views/lms/superadmin/users/bulk/actions/removefromgroups/ViewLmsSuperadminUsersBulkActionsRemoveFromGroupsPreview.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-remove-from-groups-summary',
              path: 'summary',
              component: () => import('./views/lms/superadmin/users/bulk/actions/removefromgroups/ViewLmsSuperadminUsersBulkActionsRemoveFromGroupsSummary.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            }
          ]
        },
        {
          name: 'lms-superadmin-user-bulk-actions-delete-profiles',
          path: '/users/users/bulk/delete-profiles',
          component: () => import('./views/lms/superadmin/users/bulk/actions/deleteprofiles/ViewLmsSuperadminUsersBulkActionsDeleteProfiles.vue'),
          redirect: () => {
            return { name: 'lms-superadmin-user-bulk-actions-delete-profiles-select-users' }
          },
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          },
          children: [
            {
              name: 'lms-superadmin-user-bulk-actions-delete-profiles-select-users',
              path: 'select-users',
              component: () => import('./views/lms/superadmin/users/bulk/actions/deleteprofiles/ViewLmsSuperadminUsersBulkActionsDeleteProfilesSelectUsers.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-delete-profiles-preview',
              path: 'preview',
              component: () => import('./views/lms/superadmin/users/bulk/actions/deleteprofiles/ViewLmsSuperadminUsersBulkActionsDeleteProfilesPreview.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-delete-profiles-summary',
              path: 'summary',
              component: () => import('./views/lms/superadmin/users/bulk/actions/deleteprofiles/ViewLmsSuperadminUsersBulkActionsDeleteProfilesSummary.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            }
          ]
        },
        {
          name: 'lms-superadmin-user-bulk-actions-reactivate-profiles',
          path: '/users/users/bulk/reactivate-profiles',
          component: () => import('./views/lms/superadmin/users/bulk/actions/reactivateprofiles/ViewLmsSuperadminUsersBulkActionsReactivateProfiles.vue'),
          redirect: () => {
            return { name: 'lms-superadmin-user-bulk-actions-reactivate-profiles-select-users' }
          },
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          },
          children: [
            {
              name: 'lms-superadmin-user-bulk-actions-reactivate-profiles-select-users',
              path: 'select-users',
              component: () => import('./views/lms/superadmin/users/bulk/actions/reactivateprofiles/ViewLmsSuperadminUsersBulkActionsReactivateProfilesSelectUsers.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-reactivate-profiles-preview',
              path: 'preview',
              component: () => import('./views/lms/superadmin/users/bulk/actions/reactivateprofiles/ViewLmsSuperadminUsersBulkActionsReactivateProfilesPreview.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-reactivate-profiles-summary',
              path: 'summary',
              component: () => import('./views/lms/superadmin/users/bulk/actions/reactivateprofiles/ViewLmsSuperadminUsersBulkActionsReactivateProfilesSummary.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            }
          ]
        },
        {
          name: 'lms-superadmin-user-bulk-actions-resend-invitations',
          path: '/users/users/bulk/resend-invitations',
          component: () => import('./views/lms/superadmin/users/bulk/actions/resendinvitations/ViewLmsSuperadminUsersBulkActionsResendInvitations.vue'),
          redirect: () => {
            return { name: 'lms-superadmin-user-bulk-actions-resend-invitations-select-users' }
          },
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          },
          children: [
            {
              name: 'lms-superadmin-user-bulk-actions-resend-invitations-select-users',
              path: 'select-users',
              component: () => import('./views/lms/superadmin/users/bulk/actions/resendinvitations/ViewLmsSuperadminUsersBulkActionsResendInvitationsSelectUsers.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-resend-invitations-preview',
              path: 'preview',
              component: () => import('./views/lms/superadmin/users/bulk/actions/resendinvitations/ViewLmsSuperadminUsersBulkActionsResendInvitationsPreview.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-resend-invitations-summary',
              path: 'summary',
              component: () => import('./views/lms/superadmin/users/bulk/actions/resendinvitations/ViewLmsSuperadminUsersBulkActionsResendInvitationsSummary.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            }
          ]
        },
        {
          name: 'lms-superadmin-user-bulk-actions-cancel-invitations',
          path: '/users/users/bulk/cancel-invitations',
          component: () => import('./views/lms/superadmin/users/bulk/actions/cancelinvitations/ViewLmsSuperadminUsersBulkActionsCancelInvitations.vue'),
          redirect: () => {
            return { name: 'lms-superadmin-user-bulk-actions-cancel-invitations-select-users' }
          },
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          },
          children: [
            {
              name: 'lms-superadmin-user-bulk-actions-cancel-invitations-select-users',
              path: 'select-users',
              component: () => import('./views/lms/superadmin/users/bulk/actions/cancelinvitations/ViewLmsSuperadminUsersBulkActionsCancelInvitationsSelectUsers.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-cancel-invitations-preview',
              path: 'preview',
              component: () => import('./views/lms/superadmin/users/bulk/actions/cancelinvitations/ViewLmsSuperadminUsersBulkActionsCancelInvitationsPreview.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'lms-superadmin-user-bulk-actions-cancel-invitations-summary',
              path: 'summary',
              component: () => import('./views/lms/superadmin/users/bulk/actions/cancelinvitations/ViewLmsSuperadminUsersBulkActionsCancelInvitationsSummary.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['user_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresNewLMS: true,
                allowedFor: 'users'
              }
            }
          ]
        },
        // trainers
        {
          path: '/users/trainers',
          name: 'ebs-trainer-list',
          component: () => import('./views/ebs/trainers/list/ViewTrainerList.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/trainers/:id/show',
          name: 'ebs-trainer-show',
          component: () => import('./views/ebs/trainers/show/ViewTrainerShow.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/trainers/add',
          name: 'ebs-trainer-add',
          component: () => import('./views/ebs/trainers/add/ViewTrainerAdd.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/trainers/:id/edit',
          name: 'ebs-trainer-edit',
          component: () => import('./views/ebs/trainers/edit/ViewTrainerEdit.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        // admins
        {
          path: '/users/admins',
          name: 'ebs-admin-list',
          component: () => import('./views/ebs/admins/list/ViewAdminList.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['owner', 'master_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/admins/add',
          name: 'ebs-admin-add',
          component: () => import('./views/ebs/admins/add/ViewAdminAdd.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['owner', 'master_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/admins/:userId/edit',
          name: 'ebs-admin-edit',
          redirect: { name: 'ebs-admin-edit-details' },
          component: () => import('./views/ebs/admins/edit/ViewAdminEdit.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['owner', 'master_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          },
          children: [
            {
              name: 'ebs-admin-edit-details',
              path: 'details',
              component: () => import('./views/ebs/admins/edit/ViewAdminEditDetails.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['owner', 'master_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'ebs-admin-edit-roles',
              path: 'roles',
              component: () => import('./views/ebs/admins/edit/ViewAdminEditRoles.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['owner', 'master_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'users'
              }
            }
          ]
        },
        {
          path: '/users/admins/:userId/show',
          name: 'ebs-admin-show',
          redirect: { name: 'ebs-admin-show-details' },
          component: () => import('./views/ebs/admins/show/ViewAdminShow.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['owner', 'master_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          },
          children: [
            {
              name: 'ebs-admin-show-details',
              path: 'details',
              component: () => import('./views/ebs/admins/edit/ViewAdminEditDetails.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['owner', 'master_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'users'
              }
            },
            {
              name: 'ebs-admin-show-roles',
              path: 'roles',
              component: () => import('./views/ebs/admins/edit/ViewAdminEditRoles.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['owner', 'master_administrator'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'users'
              }
            }
          ]
        },
        // org units
        {
          path: '/users/org-units/edit',
          name: 'lms-superadmin-org-units-edit',
          component: () => import('./views/lms/superadmin/org-units/edit/ViewLmsSuperadminOrgUnitsEdit.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          }
        },
        // groups
        {
          path: '/users/groups/edit',
          name: 'lms-superadmin-groups-edit',
          component: () => import('./views/lms/superadmin/groups/edit/ViewLmsSuperadminGroupsEdit.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['user_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresNewLMS: true,
            allowedFor: 'users'
          }
        },
        // superadmins
        {
          path: '/setup/superadmins/add',
          name: 'lms-superadmin-admin-add',
          component: () => import('./views/lms/superadmin/admin/add/ViewLmsSuperadminAdminAdd.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        // admins
        {
          path: '/setup/admins/add',
          name: 'lms-superadmin-masteradmin-add',
          component: () => import('./views/lms/superadmin/masteradmin/add/ViewLmsSuperadminMasteradminAdd.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        // autoenrolments
        {
          path: '/users/autoenrolments',
          name: 'ebs-autoenrolments-list',
          component: () => import('./views/ebs/autoenrolments/list/ViewAutoenrolmentsList.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS'],
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/autoenrolments/add',
          name: 'ebs-autoenrolments-add',
          component: () => import('./views/ebs/autoenrolments/add/ViewAutoenrolmentsAdd.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS'],
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/autoenrolments/:id/edit',
          name: 'ebs-autoenrolments-edit',
          component: () => import('./views/ebs/autoenrolments/edit/ViewAutoenrolmentsEdit.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS'],
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        // workflow
        {
          path: '/users/workflows',
          name: 'ebs-workflows-list',
          component: () => import('./views/lms/superadmin/workflows/list/ViewLmsSuperadminWorkflowsList.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS'],
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/workflows/add',
          name: 'ebs-workflows-add',
          component: () => import('./views/lms/superadmin/workflows/add/ViewLmsSuperadminWorkflowsAdd.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS'],
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/workflows/:id/edit',
          name: 'ebs-workflows-edit',
          component: () => import('./views/lms/superadmin/workflows/edit/ViewLmsSuperadminWorkflowsEdit.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS'],
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        },
        {
          path: '/users/workflows/log',
          name: 'ebs-workflows-log',
          component: () => import('./views/lms/superadmin/workflows/log/ViewLmsSuperadminWorkflowsLog.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS'],
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'users'
          }
        }
      ]
    },
    // reports
    {
      name: 'reports',
      path: '/reports',
      component: () => import('./views/settings/ViewReportMain.vue'),
      redirect: () => {
        const nav = [
          {
            name: 'ebs-report-dashboard-show',
            customerRoles: ['master_administrator'],
            permissions: ['learning_experience_administrator', 'manager'],
            services: ['LMS', 'EBS']
          },
          {
            name: 'ebs-report-list',
            roles: ['superadmin'],
            customerRoles: ['master_administrator'],
            permissions: ['event_coordinator', 'learning_experience_administrator'],
            services: ['LMS', 'EBS']
          }
        ]
        return performNavRedirect(nav)
      },
      children: [
        // reports
        {
          path: '/reports/dashboard',
          name: 'ebs-report-dashboard-show',
          component: () => import('./views/ebs/reports/dashboard/show/ViewReportDashboardShow.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS', 'EBS'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['learning_experience_administrator', 'manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'report'
          }
        },
        {
          path: '/reports/reports',
          name: 'ebs-report-list',
          component: () => import('./views/ebs/reports/list/ViewReportList.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS', 'EBS'],
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'report'
          }
        },
        {
          path: ':slug',
          name: 'ebs-report-show',
          component: () => import('./views/ebs/reports/show/ViewReportShow.vue'),
          meta: {
            requiresAnyCustomerService: ['EBS'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'report'
          }
        },
        {
          path: '/reports/reports/builder/execute/:id',
          name: 'ebs-report-builder-execute',
          component: () => import('./views/ebs/reports/builder/execute/ViewReportBuilderExecute.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS', 'EBS'],
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'report'
          }
        },
        {
          path: '/reports/reports/builder/execute/:reportId/:resultId',
          name: 'ebs-report-builder-execute-scheduled',
          component: () => import('./views/ebs/reports/builder/execute/ViewReportBuilderExecute.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS', 'EBS'],
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'report'
          }
        },
        {
          path: '/reports/reports/builder/execute',
          name: 'ebs-report-builder-execute-resolved',
          component: () => import('./views/ebs/reports/builder/execute/ViewReportBuilderExecute.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS', 'EBS'],
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'report'
          }
        },
        {
          path: '/reports/reports/builder',
          name: 'ebs-report-builder',
          redirect: { name: 'ebs-report-builder-add' }
        },
        {
          path: '/reports/reports/builder/add',
          name: 'ebs-report-builder-add',
          component: () => import('./views/ebs/reports/builder/add/ViewReportBuilderAdd.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS', 'EBS'],
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'report'
          }
        },
        {
          path: '/reports/reports/builder/:id/edit',
          name: 'ebs-report-builder-edit',
          component: () => import('./views/ebs/reports/builder/edit/ViewReportBuilderEdit.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS', 'EBS'],
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'report'
          }
        },
        {
          path: '/reports/reports/builder/:id/schedule',
          name: 'ebs-report-builder-schedule-edit',
          component: () => import('./views/ebs/reports/builder/schedule/ViewReportBuilderSchedule.vue'),
          meta: {
            requiresAnyCustomerService: ['LMS', 'EBS'],
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'report'
          }
        }
      ]
    },
    {
      name: 'landing-report-result-csv',
      path: '/report/:reportId/result/:resultId',
      component: () => import('./views/landing/ViewLandingReportScheduledCSVDownload.vue')
    },
    // admin
    {
      name: 'setup',
      path: '/setup',
      component: () => import('./views/settings/ViewAdminMain.vue'),
      redirect: () => {
        const user = new User()
        const nav = [
          {
            name: 'ebs-site-settings-edit-details',
            params: { id: user.workspaceId },
            roles: ['superadmin'],
            customerRoles: ['master_administrator'],
            permissions: ['admin'],
            selectedProfile: true
          },
          {
            name: 'ebs-venue-list',
            customerRoles: ['master_administrator'],
            permissions: ['infrastructure_manager', 'event_coordinator'],
            services: ['EBS']
          },
          {
            name: 'media-library-list',
            customerRoles: ['master_administrator', 'trainer'],
            selectedProfile: true,
            permissions: ['event_coordinator', 'infrastructure_manager', 'user_administrator', 'learning_experience_administrator']
          },
          {
            name: 'lms-notification-list',
            customerRoles: ['master_administrator'],
            permissions: ['event_coordinator', 'user_administrator', 'learning_experience_administrator'],
            services: ['EBS', 'LMS', 'DW']
          },
          {
            name: 'lms-superadmin-customer-list',
            roles: ['superadmin']
          },
          {
            name: 'lms-superadmin-workspace-list',
            roles: ['superadmin'],
            customerRoles: ['master_administrator']
          },
          {
            name: 'lms-superadmin-archived-list',
            roles: ['superadmin']
          }
        ]
        return performNavRedirect(nav)
      },
      children: [
        // venues
        {
          path: '/setup/venues',
          name: 'ebs-venue-list',
          component: () => import('./views/ebs/venues/list/ViewVenueList.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'infrastructure_manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/venues/:id/show',
          name: 'ebs-venue-show',
          component: () => import('./views/ebs/venues/show/ViewVenueShow.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'infrastructure_manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/venues/add',
          name: 'ebs-venue-add',
          component: () => import('./views/ebs/venues/add/ViewVenueAdd.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['infrastructure_manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/venues/:id/edit',
          name: 'ebs-venue-edit',
          component: () => import('./views/ebs/venues/edit/ViewVenueEdit.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['infrastructure_manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        // rooms
        {
          path: '/setup/venues/:venueId/rooms/:roomId/show',
          name: 'ebs-room-show',
          component: () => import('./views/ebs/rooms/show/ViewRoomShow.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['infrastructure_manager', 'event_coordinator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/venues/:venueId/rooms/add',
          name: 'ebs-room-add',
          component: () => import('./views/ebs/rooms/add/ViewRoomAdd.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['infrastructure_manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/venues/:venueId/rooms/:roomId/edit',
          name: 'ebs-room-edit',
          component: () => import('./views/ebs/rooms/edit/ViewRoomEdit.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['infrastructure_manager'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        // media library
        {
          path: '/setup/media-library',
          name: 'media-library-list',
          component: () => import('./views/media-library/list/ViewMediaLibraryList.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator', 'trainer'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'infrastructure_manager', 'user_administrator', 'learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/media-library/edit/:id',
          name: 'media-library-edit',
          component: () => import('./views/media-library/edit/ViewMediaLibraryEdit.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator', 'trainer'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['event_coordinator', 'infrastructure_manager', 'user_administrator', 'learning_experience_administrator'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        // notifications
        {
          path: '/setup/notifications',
          name: 'lms-notification-list',
          component: () => import('./views/emails/list/ViewEmailsList.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['infrastructure_manager', 'event_coordinator', 'user_administrator', 'learning_experience_administrator'],
            requiresAnyCustomerService: ['EBS', 'LMS', 'DW'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        // customers
        {
          path: '/setup/customers',
          name: 'lms-superadmin-customer-list',
          component: () => import('./views/lms/superadmin/customer/list/ViewLmsSuperadminCustomerList.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/customers/add',
          name: 'lms-superadmin-customer-add',
          redirect: { name: 'lms-superadmin-customer-add-details' },
          component: () => import('./views/lms/superadmin/customer/add/ViewLmsSuperadminCustomerAdd.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          },
          children: [
            {
              name: 'lms-superadmin-customer-add-details',
              path: 'details',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditDetails.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-customer-add-contacts',
              path: 'contacts',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditContacts.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-customer-add-options',
              path: 'options',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditOptions.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-customer-add-services',
              path: 'services',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditServices.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-customer-add-workspaces',
              path: 'workspaces',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditWorkspaces.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            }
          ]
        },
        {
          path: '/setup/customers/:id/edit',
          name: 'lms-superadmin-customer-edit',
          redirect: () => {
            return { name: 'lms-superadmin-customer-edit-details' }
          },
          component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEdit.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          },
          children: [
            {
              name: 'lms-superadmin-customer-edit-details',
              path: 'details',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditDetails.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-customer-edit-contacts',
              path: 'contacts',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditContacts.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-customer-edit-options',
              path: 'options',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditOptions.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-customer-edit-services',
              path: 'services',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditServices.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-customer-edit-email-senders',
              path: 'email-senders',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditEmailSenders.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-customer-edit-workspaces',
              path: 'workspaces',
              component: () => import('./views/lms/superadmin/customer/edit/ViewLmsSuperadminCustomerEditWorkspaces.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            }
          ]
        },
        {
          path: '/setup/customers/:customerId/contact/add',
          name: 'lms-superadmin-customer-contact-add',
          component: () => import('./views/lms/superadmin/customer/contact/add/ViewLmsSuperadminCustomerContactAdd.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/customers/:customerId/contact/:contactId/edit',
          name: 'lms-superadmin-customer-contact-edit',
          component: () => import('./views/lms/superadmin/customer/contact/edit/ViewLmsSuperadminCustomerContactEdit.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/customers/:customerId/services/:serviceKey/edit',
          name: 'lms-superadmin-customer-service-edit',
          component: () => import('./views/lms/superadmin/customer/service/show/ViewLmsSuperadminCustomerServiceShow.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/customers/:customerId/services/sso/add',
          name: 'lms-superadmin-customer-service-sso-add',
          component: () => import('./views/lms/superadmin/customer/service/add/ViewLmsSuperadminCustomerServiceSSOAdd.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          },
          redirect: { name: 'lms-superadmin-customer-service-sso-add-mel-setup' },
          children: [
            {
              path: 'mel-setup',
              name: 'lms-superadmin-customer-service-sso-add-mel-setup',
              component: () => import('./views/lms/superadmin/customer/service/edit/ViewLmsSuperadminCustomerServiceSSOEditMelSetup.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              path: 'customer-setup',
              name: 'lms-superadmin-customer-service-sso-add-customer-setup',
              component: () => import('./views/lms/superadmin/customer/service/edit/ViewLmsSuperadminCustomerServiceSSOEditCustomerSetup.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            }
          ]
        },
        {
          path: '/setup/customers/:customerId/services/sso/:subscriptionId/edit',
          name: 'lms-superadmin-customer-service-sso-edit',
          component: () => import('./views/lms/superadmin/customer/service/edit/ViewLmsSuperadminCustomerServiceSSOEdit.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          },
          redirect: { name: 'lms-superadmin-customer-service-sso-edit-mel-setup' },
          children: [
            {
              path: 'mel-setup',
              name: 'lms-superadmin-customer-service-sso-edit-mel-setup',
              component: () => import('./views/lms/superadmin/customer/service/edit/ViewLmsSuperadminCustomerServiceSSOEditMelSetup.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              path: 'authentication',
              name: 'lms-superadmin-customer-service-sso-edit-authentication',
              component: () => import('./views/lms/superadmin/customer/service/edit/ViewLmsSuperadminCustomerServiceSSOEditAuthentication.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              path: 'customer-setup',
              name: 'lms-superadmin-customer-service-sso-edit-customer-setup',
              component: () => import('./views/lms/superadmin/customer/service/edit/ViewLmsSuperadminCustomerServiceSSOEditCustomerSetup.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            }
          ]
        },
        // workspaces
        {
          path: '/setup/site-settings/:id/edit',
          name: 'ebs-site-settings-edit',
          component: () => import('./views/lms/superadmin/site-settings/edit/ViewLmsSuperadminSiteSettingsEdit.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresAnyPermission: ['admin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            requiresSelectedProfile: true,
            allowedFor: 'setup'
          },
          redirect: { name: 'ebs-site-settings-edit-details' },
          children: [
            {
              name: 'ebs-site-settings-edit-details',
              path: 'details',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditDetails.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresSelectedProfile: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'ebs-site-settings-edit-appearance',
              path: 'appearance',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditAppearance.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresSelectedProfile: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'ebs-site-settings-edit-onboarding',
              path: 'onboarding',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditOnboarding.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresSelectedProfile: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'ebs-site-settings-edit-footer',
              path: 'footer',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditFooter.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresSelectedProfile: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'ebs-site-settings-edit-certificates',
              path: 'certificates',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditCertificates.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresSelectedProfile: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'ebs-site-settings-edit-custom-fields',
              path: 'custom-fields',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditCustomFields.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresSelectedProfile: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'ebs-site-settings-edit-self-registration',
              path: 'self-registration',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditSelfRegistration.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresSelectedProfile: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'ebs-site-settings-edit-feedback-forms',
              path: 'feedback-forms',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditFeedbackForms.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresSelectedProfile: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'ebs-site-settings-edit-services',
              path: 'services',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditServices.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresSelectedProfile: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'ebs-site-settings-edit-api-integration',
              path: 'api-integration',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditApiIntegration.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresAnyPermission: ['admin'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresSelectedProfile: true,
                allowedFor: 'setup'
              }
            }
          ]
        },
        {
          name: 'ebs-site-settings-edit-self-registration-add',
          path: '/setup/site-settings/:workspaceId/edit/self-registration/add',
          component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditSelfRegistrationAdd.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          name: 'ebs-site-settings-edit-self-registration-edit',
          path: '/setup/site-settings/:workspaceId/edit/self-registration/:keyId/edit',
          component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditSelfRegistrationEdit.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          name: 'ebs-site-settings-edit-feedback-forms-add',
          path: '/setup/site-settings/:workspaceId/edit/feedback-forms/add',
          component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditFeedbackFormsAdd.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          name: 'ebs-site-settings-edit-feedback-forms-edit',
          path: '/setup/site-settings/:workspaceId/edit/feedback-forms/:feedbackFormId/edit',
          component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditFeedbackFormsEdit.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/workspaces',
          name: 'lms-superadmin-workspace-list',
          component: () => import('./views/lms/superadmin/workspace/list/ViewLmsSuperadminWorkspaceList.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerRole: ['master_administrator'],
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/workspaces/add',
          name: 'lms-superadmin-workspace-add',
          redirect: { name: 'lms-superadmin-workspace-add-details' },
          component: () => import('./views/lms/superadmin/workspace/add/ViewLmsSuperadminWorkspaceAdd.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerRole: ['master_administrator'],
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          },
          children: [
            {
              name: 'lms-superadmin-workspace-add-details',
              path: 'details',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditDetails.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresRoleOrService: true,
                requiresAnyCustomerRole: ['master_administrator'],
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresRoleOrPermissions: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-add-appearance',
              path: 'appearance',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditAppearance.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresFirstStageToken: true,
                requiresRoleOrService: true,
                requiresAnyCustomerRole: ['master_administrator'],
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresRoleOrPermissions: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-add-onboarding',
              path: 'onboarding',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditOnboarding.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresFirstStageToken: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresRoleOrPermissions: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-add-footer',
              path: 'footer',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditFooter.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresFirstStageToken: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresRoleOrPermissions: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-add-certificates',
              path: 'certificates',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditCertificates.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresFirstStageToken: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresRoleOrPermissions: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-add-custom-fields',
              path: 'custom-fields',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditCustomFields.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresFirstStageToken: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresRoleOrPermissions: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-add-self-registration',
              path: 'self-registration',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditSelfRegistration.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresFirstStageToken: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresRoleOrPermissions: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-add-feedback-forms',
              path: 'feedback-forms',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditFeedbackForms.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresFirstStageToken: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresRoleOrPermissions: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-add-services',
              path: 'services',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditServices.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            }
          ]
        },
        {
          path: '/setup/workspaces/:id/edit',
          name: 'lms-superadmin-workspace-edit',
          redirect: { name: 'lms-superadmin-workspace-edit-details' },
          component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEdit.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          },
          children: [
            {
              name: 'lms-superadmin-workspace-edit-details',
              path: 'details',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditDetails.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-edit-appearance',
              path: 'appearance',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditAppearance.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-edit-onboarding',
              path: 'onboarding',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditOnboarding.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-edit-footer',
              path: 'footer',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditFooter.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-edit-certificates',
              path: 'certificates',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditCertificates.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-edit-custom-fields',
              path: 'custom-fields',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditCustomFields.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresFirstStageToken: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresRoleOrPermissions: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-edit-self-registration',
              path: 'self-registration',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditSelfRegistration.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-edit-feedback-forms',
              path: 'feedback-forms',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditFeedbackForms.vue'),
              meta: {
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-edit-services',
              path: 'services',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditServices.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-superadmin-workspace-edit-api-integration',
              path: 'api-integration',
              component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditApiIntegration.vue'),
              meta: {
                requiresAnyRole: ['superadmin'],
                requiresAnyCustomerRole: ['master_administrator'],
                requiresRoleOrPermissions: true,
                requiresRoleOrService: true,
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            }
          ]
        },
        {
          name: 'lms-superadmin-workspace-edit-self-registration-add',
          path: '/setup/workspaces/:workspaceId/edit/self-registration/add',
          component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditSelfRegistrationAdd.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          name: 'lms-superadmin-workspace-edit-self-registration-edit',
          path: '/setup/workspaces/:workspaceId/edit/self-registration/:keyId/edit',
          component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditSelfRegistrationEdit.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          name: 'lms-superadmin-workspace-edit-feedback-forms-add',
          path: '/setup/workspaces/:workspaceId/edit/feedback-forms/add',
          component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditFeedbackFormsAdd.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          name: 'lms-superadmin-workspace-edit-feedback-forms-edit',
          path: '/setup/workspaces/:workspaceId/edit/feedback-forms/:feedbackFormId/edit',
          component: () => import('./views/lms/superadmin/workspace/edit/ViewLmsSuperadminWorkspaceEditFeedbackFormsEdit.vue'),
          meta: {
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        // archived
        {
          path: '/setup/archived',
          name: 'lms-superadmin-archived-list',
          component: () => import('./views/lms/superadmin/archived/list/ViewLmsSuperadminArchivedList.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        // achievements
        {
          path: '/setup/achievements',
          name: 'lms-user-achievement-list',
          component: () => import('./views/lms/user/achievements/list/ViewLmsUserAchievementsList.vue'),
          meta: {
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        {
          path: '/setup/achievements/:id/edit',
          name: 'lms-user-achievement-edit',
          redirect: { name: 'lms-user-achievement-edit-details' },
          component: () => import('./views/lms/user/achievements/edit/ViewLmsUserAchievementsEdit.vue'),
          meta: {
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          },
          children: [
            {
              name: 'lms-user-achievement-edit-details',
              path: 'details',
              component: () => import('./views/lms/user/achievements/edit/ViewLmsUserAchievementsEditDetails.vue'),
              meta: {
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-user-achievement-edit-rules',
              path: 'rules',
              component: () => import('./views/lms/user/achievements/edit/ViewLmsUserAchievementsEditRules.vue'),
              meta: {
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-user-achievement-edit-reward',
              path: 'reward',
              component: () => import('./views/lms/user/achievements/edit/ViewLmsUserAchievementsEditRewards.vue'),
              meta: {
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            }
          ]
        },
        {
          path: '/setup/achievements/add',
          name: 'lms-user-achievement-add',
          redirect: { name: 'lms-user-achievement-add-details' },
          component: () => import('./views/lms/user/achievements/add/ViewLmsUserAchievementsAdd.vue'),
          meta: {
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          },
          children: [
            {
              name: 'lms-user-achievement-add-details',
              path: 'details',
              component: () => import('./views/lms/user/achievements/edit/ViewLmsUserAchievementsEditDetails.vue'),
              meta: {
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-user-achievement-add-rules',
              path: 'rules',
              component: () => import('./views/lms/user/achievements/edit/ViewLmsUserAchievementsEditRules.vue'),
              meta: {
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            },
            {
              name: 'lms-user-achievement-add-reward',
              path: 'reward',
              component: () => import('./views/lms/user/achievements/edit/ViewLmsUserAchievementsEditRewards.vue'),
              meta: {
                requiresAnyPermission: ['learning_experience_administrator'],
                requiresAnyCustomerService: ['EBS', 'LMS'],
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                allowedFor: 'setup'
              }
            }
          ]
        },
        {
          path: '/setup/user/reward/add',
          name: 'lms-user-achievement-reward-add',
          component: () => import('./views/lms/user/rewards/add/ViewLmsUserRewardsAdd.vue'),
          meta: {
            requiresAnyPermission: ['learning_experience_administrator'],
            requiresAnyCustomerService: ['EBS', 'LMS'],
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        },
        // audit log
        {
          path: '/setup/audit-log',
          name: 'lms-superadmin-audit-log-list',
          component: () => import('./views/lms/superadmin/audit-log/list/ViewLmsSuperadminAuditLogList.vue'),
          meta: {
            requiresAnyRole: ['superadmin'],
            requiresAnyCustomerRole: ['master_administrator'],
            requiresRoleOrPermissions: true,
            requiresRoleOrService: true,
            requiresFirstStageToken: true,
            requiresSecondStageToken: true,
            allowedFor: 'setup'
          }
        }
      ]
    },
    // my learning
    {
      name: 'my-learning',
      path: '/my-learning',
      component: () => import('./views/my-learning/ViewMyLearningMain.vue'),
      redirect: () => {
        const nav = [
          {
            name: 'dashboard',
            customerRoles: ['trainer'],
            permissions: ['learner'],
            isNewLMS: true
          }
        ]
        return performNavRedirect(nav)
      },
      children: [
        {
          name: 'dashboard',
          path: '/my-learning/dashboard',
          component: () => import('./views/dashboard/ViewDashboardMain.vue'),
          redirect: () => {
            const nav = [
              {
                name: 'dashboard-courses',
                permissions: ['learner'],
                services: ['LMS']
              },
              {
                name: 'dashboard-trainer',
                customerRoles: ['trainer']
              },
              {
                name: 'dashboard-learner',
                permissions: ['learner'],
                services: ['EBS']
              }
            ]
            return performNavRedirect(nav)
          },
          children: [
            {
              name: 'dashboard-trainer',
              path: 'trainer',
              component: () => import('./views/dashboard/list/trainer/ViewDashboardListTrainer.vue'),
              meta: {
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresAnyCustomerRole: ['trainer'],
                allowedFor: 'my-learning'
              }
            },
            {
              name: 'dashboard-learner',
              path: 'learner',
              component: () => import('./views/dashboard/list/learner/ViewDashboardListLearner.vue'),
              meta: {
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresAnyPermission: ['learner'],
                requiresAnyCustomerService: ['EBS'],
                allowedFor: 'my-learning'
              }
            },
            {
              name: 'dashboard-courses',
              path: 'courses',
              component: () => import('./views/dashboard/list/enrolments/ViewDashboardListEnrolments.vue'),
              meta: {
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresAnyPermission: ['learner'],
                requiresAnyCustomerService: ['LMS'],
                allowedFor: 'my-learning'
              }
            },
            {
              name: 'dashboard-browse',
              path: 'browse',
              component: () => import('./views/dashboard/list/browse/ViewDashboardListBrowse.vue'),
              meta: {
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresAnyPermission: ['manager', 'learner'],
                allowedFor: 'my-learning'
              }
            },
            {
              name: 'dashboard-completions',
              path: 'completions',
              component: () => import('./views/dashboard/list/completions/ViewDashboardListCompletions.vue'),
              meta: {
                requiresFirstStageToken: true,
                requiresSecondStageToken: true,
                requiresAnyPermission: ['learner'],
                requiresAnyCustomerService: ['EBS', 'LMS'],
                allowedFor: 'my-learning'
              }
            }
          ]
        }
      ]
    },
    {
      name: 'my-learning-session-show',
      path: '/my-learning/event/:eventId/session/:sessionId/show/single',
      component: () => import('./views/ebs/sessions/show/ViewSessionShow.vue'),
      meta: {
        requiresAnyCustomerRole: ['trainer', 'master_administrator'],
        requiresRoleOrPermissions: true,
        requiresAnyPermission: ['event_coordinator', 'infrastructure_manager', 'learner', 'course_creator', 'learning_experience_administrator', 'manager'],
        requiresFirstStageToken: true,
        requiresSecondStageToken: true,
        allowedFor: 'catalogue'
      }
    },
    // auth
    {
      path: '/',
      name: 'default',
      redirect: () => {
        const user = new User()
        const isTokenFromSSO = user.isTokenFromSSO()
        const firstStageToken = TokenHelper.getToken('firstStageToken')
        const secondStageToken = TokenHelper.getToken('secondStageToken')
        const secondStageTokenPayload = TokenHelper.getPayload(secondStageToken)
        const hasSelectedProfile = secondStageTokenPayload ? Boolean(secondStageTokenPayload.workspaceId) : false
        const isOldLMSUser = secondStageToken ? secondStageTokenPayload.origin === 'subdomain' : false

        if (firstStageToken || isTokenFromSSO) {
          if (hasSelectedProfile) {
            if (user.isSiteAdmin()) {
              return { name: 'home' }
            } else if ((user.isLearningExperienceAdministrator() || user.isManager()) && user.hasAnyCustomerService(['LMS'])) {
              return { name: 'home' }
            } else if (user.isUserAdministrator()) {
              return { name: 'home' }
            } else if (user.isCourseCreator() && user.hasAnyCustomerService(['CCS'])) {
              return { name: 'home' }
            } else if (user.isCourseLicenceManager() && user.hasAnyCustomerService(['CCS'])) {
              return { name: 'home' }
            } else if (user.isEventCoordinator() && user.hasAnyCustomerService(['EBS'])) {
              return { name: 'home' }
            } else if (user.isInfrastructureManager() && user.hasAnyCustomerService(['EBS'])) {
              return { name: 'home' }
            } else if (user.isTrainer() && user.hasAnyCustomerService(['EBS'])) {
              return { name: 'dashboard-trainer' }
            } else if (user.isLearner()) {
              if (user.hasAnyCustomerService(['LMS'])) {
                return { name: 'dashboard-courses' }
              } else if (user.hasAnyCustomerService(['EBS'])) {
                return { name: 'dashboard-learner' }
              } else {
                return { name: 'lms-user-account-personal' }
              }
            } else {
              return { name: 'lms-user-account-personal' }
            }
          } else {
            return { name: 'lms-user-workspace-list' }
          }
        } else if (secondStageToken) {
          if (isOldLMSUser) {
            if (user.isEventCoordinator() || user.isInfrastructureManager()) {
              return { name: 'home' }
            } else {
              return { name: 'ebs-event-list' }
            }
          } else {
            return { name: 'lms-user-account-personal' }
          }
        } else {
          return { name: 'auth-login' }
        }
      }
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('./views/auth/show/ViewAuthShow.vue')
    },
    {
      path: '/auth/login',
      name: 'auth-login',
      component: () => import('./views/auth/login/ViewAuthLogin.vue'),
      meta: { hideAuthLogin: true }
    },
    {
      path: '/auth/self-registration/:key',
      name: 'auth-self-registration',
      component: () => import('./views/auth/selfregistration/ViewAuthSelfRegistration.vue')
    },
    {
      path: '/auth/confirm-registration/:userId/:code',
      name: 'auth-confirm-registration',
      component: () => import('./views/auth/confirmregistration/ViewAuthConfirmRegistration.vue')
    },
    {
      path: '/auth/complete-registration',
      name: 'auth-complete-registration',
      component: () => import('./views/auth/completeregistration/ViewAuthCompleteRegistration.vue')
    },
    {
      path: '/auth/validate-key',
      name: 'auth-validate-key',
      component: () => import('./views/auth/validatekey/ViewAuthValidateKey.vue')
    },
    {
      path: '/auth/sign-up',
      name: 'auth-signup',
      component: () => import('./views/auth/signup/ViewAuthSignup.vue')
    },
    {
      path: '/auth/reset-password',
      name: 'auth-reset-password',
      component: () => import('./views/auth/resetpassword/ViewAuthResetpassword.vue')
    },
    {
      path: '/dashboard/two-factor-auth',
      name: 'auth-two-factor',
      component: () => import('./views/auth/twofactorauth/ViewAuthTwofactorauth.vue')
    },
    {
      path: '/auth/two-factor-auth-help',
      name: 'auth-two-factor-help',
      component: () => import('./views/auth/twofactorauthhelp/ViewAuthTwofactorauthhelp.vue')
    },
    // invitations
    {
      path: '/lms/user/invitation/accept',
      name: 'lms-user-invitation-accept',
      component: () => import('./views/lms/user/invitation/ViewLmsUserInvitation.vue')
    },
    {
      path: '/lms/user/invitation/reject',
      name: 'lms-user-invitation-reject',
      component: () => import('./views/lms/user/invitation/ViewLmsUserInvitation.vue')
    },
    {
      path: '/lms/user/invitation/error',
      name: 'lms-user-invitation-error',
      component: () => import('./views/lms/user/invitation/ViewLmsUserInvitation.vue')
    },
    {
      path: '/lms/user/invitation/resolved',
      name: 'lms-user-invitation-resolved',
      component: () => import('./views/lms/user/invitation/ViewLmsUserInvitation.vue')
    },
    // account
    {
      path: '/lms/user/account',
      name: 'lms-user-account-edit',
      redirect: { name: 'lms-user-account-personal' },
      component: () => import('./views/lms/user/account/ViewLmsUserAccount.vue'),
      meta: {
        requiresFirstStageToken: true
      },
      children: [
        {
          name: 'lms-user-account-personal',
          path: 'personal',
          component: () => import('./views/lms/user/account/ViewLmsUserAccountPersonal.vue'),
          meta: {
            requiresFirstStageToken: true
          }
        },
        {
          name: 'lms-user-account-security',
          path: 'security',
          component: () => import('./views/lms/user/account/ViewLmsUserAccountSecurity.vue'),
          meta: {
            requiresFirstStageToken: true
          }
        },
        {
          name: 'lms-user-account-learning-history',
          path: 'learning-history',
          component: () => import('./views/lms/user/account/ViewLmsUserAccountLearningHistory.vue'),
          meta: {
            requiresFirstStageToken: true
          }
        },
        {
          name: 'lms-user-account-activity-log',
          path: 'activity-log',
          component: () => import('./views/lms/user/account/ViewLmsUserAccountActivityLog.vue'),
          meta: {
            requiresFirstStageToken: true
          }
        }
      ]
    },
    // workspaces
    {
      path: '/lms/user/workspaces',
      name: 'lms-user-workspace-list',
      component: () => import('./views/lms/user/workspaces/list/ViewLmsUserWorkspaceList')
    },
    // landing
    {
      path: '/landing',
      name: 'landing',
      component: () => import('./views/landing/ViewLanding.vue')
    },
    {
      path: '/landing-invitation',
      name: 'landing-invitation',
      component: () => import('./views/landing/ViewLandingInvitation.vue')
    },
    {
      path: '/landing-unsubscribe',
      name: 'landing-unsubscribe',
      component: () => import('./views/landing/ViewLandingUnsubscribe.vue')
    },
    {
      path: '/course/:id/add-event/oldlms',
      name: 'event-course-assign-old-lms',
      component: () => import('./views/oldlms/AssignCourseToEventFromLMS.vue'),
      meta: {
        requiresFirstStageToken: true,
        requiresSecondStageToken: true
      }
    },
    {
      path: '/booking-event/:courseId/:moduleId/:domain/oldlms',
      name: 'event-show-old-lms',
      component: () => import('./views/oldlms/ShowEventFromLMS.vue'),
      meta: {
        requiresFirstStageToken: true,
        requiresSecondStageToken: true,
        allowedFor: 'catalogue'
      }
    },
    // other
    { path: '/404', name: '404', component: () => import('./views/default/errors/ViewError404.vue') }
  ]
})

router.beforeEach((to, from, next) => {
  let firstStageToken = TokenHelper.getToken('firstStageToken')
  let secondStageToken = TokenHelper.getToken('secondStageToken')
  const secondStageTokenPayload = to.name === 'landing' ? TokenHelper.getPayload(to.query.token) : TokenHelper.getPayload(secondStageToken)
  const currentWorkspaceId = secondStageTokenPayload ? secondStageTokenPayload.workspaceId : undefined
  const user = new User(secondStageTokenPayload)
  const isSecondStageTokenFromOldLMS = user.isTokenFromOldLms()
  const isInvitation = store.getters.getAuthUserSignUpInvitation
  const forceRedirect = store.getters.getAuthUserForceRedirect
  const sessionStorageListSearchTerms = Storage.getSession('listSearchTerms') ? JSON.parse(Storage.getSession('listSearchTerms')) : undefined

  const requiresFirstStageToken = to.meta.requiresFirstStageToken
  const requiresSecondStageToken = to.meta.requiresSecondStageToken
  const requiresAnyCustomerService = to.meta.requiresAnyCustomerService
  const requiresAnyPermission = to.meta.requiresAnyPermission
  const requiresAnyRole = to.meta.requiresAnyRole
  const requiresAnyCustomerRole = to.meta.requiresAnyCustomerRole
  const requiresRoleOrPermissions = to.meta.requiresRoleOrPermissions
  const requiresRoleOrService = to.meta.requiresRoleOrService
  const requiresOldLMS = to.meta.requiresOldLMS
  const requiresNewLMS = to.meta.requiresNewLMS
  const requiresSelectedProfile = to.meta.requiresSelectedProfile

  const checkHasEmptyRequiredCustomFields = store.getters.getAuthUserHasEmptyRequiredCustomFields
  const checkHasMfaRequired = store.getters.getAuthUserHasMfaRequired
  const checkHasMfaEnabled = store.getters.getAuthUserHasMfaEnabled
  const checkHasAnyRole = requiresAnyRole ? user.hasAnyRole(requiresAnyRole) : false
  const checkHasAnyCustomerRole = requiresAnyCustomerRole ? user.hasAnyCustomerRole(requiresAnyCustomerRole) : false
  const checkHasAnyPermission = requiresAnyPermission ? user.hasAnyPermission(requiresAnyPermission) : false
  const checkHasAnyCustomerService = requiresAnyCustomerService ? user.hasAnyCustomerService(requiresAnyCustomerService) : false

  const performCheckRoleAndService = () => {
    // check services to display specific page
    if (requiresRoleOrService && !isSecondStageTokenFromOldLMS) {
      if ((requiresAnyRole || requiresAnyCustomerRole) && requiresAnyCustomerService) {
        if ((!checkHasAnyRole && !checkHasAnyCustomerRole) && !checkHasAnyCustomerService) {
          next({ name: '404', replace: true })
        }
      }
    } else {
      if (requiresAnyCustomerService) {
        if (!checkHasAnyCustomerService && !isSecondStageTokenFromOldLMS) {
          next({ name: '404', replace: true })
        }
      }
    }
  }
  const performCheckRoleAndPermission = () => {
    if (requiresRoleOrPermissions) {
      // check roles to display specific page
      if ((requiresAnyRole || requiresAnyCustomerRole) && requiresAnyPermission) {
        if ((!checkHasAnyRole && !checkHasAnyCustomerRole) && !checkHasAnyPermission) {
          next({ name: '404', replace: true })
        }
      }
    } else {
      // check profile roles to display specific page
      if ((requiresAnyRole || requiresAnyCustomerRole)) {
        if ((!checkHasAnyRole && !checkHasAnyCustomerRole)) {
          next({ name: '404', replace: true })
        }
      }
      if (requiresAnyPermission) {
        if (!checkHasAnyPermission) {
          next({ name: '404', replace: true })
        }
      }
    }
  }
  const performCheckTokens = () => {
    // checks if user comes from old LMS
    if (secondStageToken && isSecondStageTokenFromOldLMS) {
      // validate secondStageToken if route requires it
      store.dispatch('authUserValidateSecondStageToken', { preventRedirect: true, preventNotification: true, allowLogoutOldLMS: true }).then(() => {
        // initialize user profiles
        next()
      }).catch(() => resetForceRedirect())
    } else {
      if (requiresFirstStageToken && requiresSecondStageToken) {
        // validate firstStageToken & secondStageToken if route requires them
        store.dispatch('authUserValidateFirstStageToken').then(() => {
          store.dispatch('authUserValidateSecondStageToken').then(() => {
            // initialize user profiles
            next()
          }).catch(() => resetForceRedirect())
        }).catch(() => resetForceRedirect())
      } else if (requiresFirstStageToken) {
        // validate firstStageToken if route requires it
        store.dispatch('authUserValidateFirstStageToken').then(() => {
          // initialize user profiles
          next()
        }).catch(() => resetForceRedirect())
      } else if (requiresSecondStageToken) {
        // validate secondStageToken if route requires it
        store.dispatch('authUserValidateSecondStageToken').then(() => {
          // initialize user profiles
          next()
        }).catch(() => resetForceRedirect())
      } else {
        // initialize user profiles
        next()
      }
    }
  }
  const performCancelAxiosRequests = () => {
    store.dispatch('storageCancelTokenCancel')
  }

  // stop all loading animations on the page
  eventbus.$eventBus.$emit('stop-loading')

  // check if next page is a child of route that has setup of list search terms
  if (sessionStorageListSearchTerms && !to.path.includes(sessionStorageListSearchTerms.routePath) && !user.isInpersonated()) {
    Storage.removeSession('listSearchTerms')
  }

  // remove classroom-booking phrase if is present in url
  if (
      to.fullPath.includes('classroom-booking/') ||
      to.fullPath.includes('classroom-booking%2F')
  ) {
    const url = to.fullPath
    const newUrl = url.replace('classroom-booking/', '').replace('classroom-booking%2F', '')
    location.replace(newUrl)
  }

  // redirect if path route is not defined
  if (!to.name) {
    next({ name: '404' })
  }

  // check if user has enabled MFA
  if (
      (to.name === 'lms-user-account-security' && firstStageToken) ||
      (to.name === 'lms-user-account-personal' && checkHasEmptyRequiredCustomFields)
  ) {
    next()
    return
  }
  if (
      checkHasMfaRequired &&
      !checkHasMfaEnabled
  ) {
    next({ name: 'lms-user-account-security' })
    store.dispatch('defaultNotificationToastsAdd', {
      title: '2FA required',
      message: `You have to set up two factor authentication.`,
      status: 'error'
    })
    return
  }
  if (checkHasEmptyRequiredCustomFields) {
    next({ name: 'lms-user-account-personal' })
    store.dispatch('defaultNotificationToastsAdd', {
      title: 'Empty user fields',
      message: `There is some missing information. \nPlease update the required profile fields.`,
      status: 'error'
    })
    return
  }

  // logic for user that comes to the site from a link received in an email
  if (to.query.source && to.query.source === 'email') {
    let queryArr = JSON.parse(JSON.stringify(to.query))
    queryArr.source = undefined
    if (to.query.ownerType && to.query.ownerType === 'workspace') {
      const targetPath = to.fullPath.split('?')[0]
      store.dispatch('authUserValidateFirstStageToken', { preventAny: true }).then(() => {
        store.dispatch('authUserValidateSecondStageToken', { preventAny: true }).then(() => {
          if (to.query.externalId === currentWorkspaceId) {
            next({ path: targetPath, query: queryArr })
          } else {
            store.dispatch('authUserLoginSSOWorkspace', to.query.externalId).then(() => {
              store.dispatch('defaultNotificationToastsAdd', {
                title: 'Workspace access',
                message: `Your workspace has been switched.`,
                status: 'success'
              })
              secondStageToken = TokenHelper.getToken('secondStageToken')
              next({ path: targetPath, query: queryArr })
            }).catch(() => {
              next({ name: 'lms-user-workspace-list' })
            })
          }
        }).catch(() => {
          store.dispatch('authUserLoginSSOWorkspace', to.query.externalId).then(() => {
            store.dispatch('defaultNotificationToastsAdd', {
              title: 'Workspace access',
              message: `You have accessed the workspace successfully.`,
              status: 'success'
            })
            secondStageToken = TokenHelper.getToken('secondStageToken')
            next({ path: targetPath, query: queryArr })
          }).catch(() => {
            next({ name: 'lms-user-workspace-list' })
          })
        })
      }).catch(() => {
        next({ name: 'auth-login', query: { targetPath: to.fullPath, targetWorkspace: to.query.externalId } })
      })
      return
    } else if (to.query.ownerType && to.query.ownerType === 'subdomain') {
      const redirectToLms = () => {
        let params = {}
        if (to.query.externalId && to.query.userType) {
          // we're all set, build redirect link
          params = to.query
        } else if (to.hash) {
          // check hash for query params
          let paramString = to.hash.split('?')
          params = paramString[1] ? parseQuery(paramString[1]) : {}
        }
        // redirect to login
        if (params.externalId && params.userType) {
          // get name of subdomain
          store.dispatch('authUserGetSubdomain', params.externalId).then(response => {
            let domain = response.name
            let baseUrl = process.env.VUE_APP_LMS_SAAS_URL.replace('[domain]', domain)
            let url = `${ baseUrl }${ params.userType }/login`
            location.replace(url)
          }).finally(() => {

          })
        } else if (
            (
                !to.query.externalId ||
                !to.query.userType
            ) && (
                to.fullPath.includes('ownerType') &&
                to.fullPath.includes('userType')
            )
        ) {
          let url = to.fullPath
          let newUrl = url.slice(0, url.indexOf('?'))
          location.replace(newUrl)
        }
      }

      store.dispatch('authUserValidateSecondStageToken', { preventAny: true }).then(() => {
        if (to.query.externalId !== TokenHelper.getPayload(secondStageToken).subdomains[0]) {
          redirectToLms()
        }
      }).catch(() => {
        redirectToLms()
      })
    }
  }

  // rules for redirection without checking other statements
  if (
      (
          to.query.token &&
          to.query.redirect
      ) ||
      (
          to.name.indexOf('auth') === 0 &&
          to.name.indexOf('auth-signup') === -1
      ) ||
      to.name === '404' ||
      to.name === 'landing' ||
      to.name.includes('landing') ||
      forceRedirect
  ) {
    next()
    return
  }

  // allow for self sign up only when user has an invitation
  if (to.name === 'auth-signup') {
    if (!isInvitation) {
      next({ name: 'auth-login' })
    } else {
      if (store.state.route.name !== to.name) {
        store.dispatch('authUserSetForceRedirect', true)
        next({ name: 'auth-signup' })
      }
    }
  }

  // check if is old LMS to display specific page
  if (requiresOldLMS) {
    if (user.isTokenFromOldLms() === false) {
      next({ name: '404', replace: true })
    }
  }

  // check if is new LMS to display specific page
  if (requiresNewLMS) {
    if (user.isTokenFromNewLms() === false) {
      next({ name: '404', replace: true })
    }
  }

  if (requiresSelectedProfile) {
    if (user.hasSelectedProfile() === false) {
      next({ name: '404', replace: true })
    }
  }

  store.dispatch('authUserInitializeProfiles')
  store.dispatch('defaultInductionMessagesInitialize')
  store.dispatch('defaultWorkspaceInitialize')
  store.dispatch('defaultCustomerOptionsGetOptions')
  store.dispatch('lmsSuperadminCustomerGetCCSSettings').catch(() => {})
  store.dispatch('authUserInitializeRequests', { to })

  if (!to.meta.parent || !from.meta.parent || to.meta.parent !== from.meta.parent) {
    performCancelAxiosRequests()
  }
  performCheckTokens()
  performCheckRoleAndService()
  performCheckRoleAndPermission()
})

router.afterEach(() => {
  store.dispatch('storageCancelTokenSet')
  store.dispatch('authUserRefreshSecondStageToken').catch(() => {})
  resetForceRedirect()
  scrollToTop()
})

const loadingChunkFailedErrorRegex = /loading .*?chunk .*?failed/i
router.onError((error) => {
  if (loadingChunkFailedErrorRegex.test(error.message)) {
    window.location.reload()
  }
})

function parseQuery (queryString) {
  let query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }

  return query
}

function resetForceRedirect () {
  if (store.getters.getAuthUserForceRedirect) {
    store.dispatch('authUserSetForceRedirect', false)
  }
}

function performNavRedirect (nav) {
  const user = new User()

  for (let indexNav in nav) {
    const isNewLMS = nav[indexNav].isNewLMS
    const permissions = nav[indexNav].permissions || []
    const roles = nav[indexNav].roles || []
    const customerRoles = nav[indexNav].customerRoles || []
    const services = nav[indexNav].services || []
    const selectedProfile = nav[indexNav].selectedProfile

    const hasAnyPermission = user.hasAnyPermission(permissions)
    const hasAnyRole = user.hasAnyRole(roles)
    const hasAnyCustomerRole = user.hasAnyCustomerRole(customerRoles)
    const hasAnyService = services.length ? user.hasAnyCustomerService(services) : true
    const hasSelectedProfile = user.hasSelectedProfile()

    if (selectedProfile === undefined || (selectedProfile && hasSelectedProfile)) {
      if (isNewLMS === undefined || (isNewLMS && user.isTokenFromNewLms())) {
        if (permissions.length && (roles.length || customerRoles.length)) {
          if ((hasAnyPermission || (hasAnyRole || hasAnyCustomerRole)) && hasAnyService) {
            return { name: nav[indexNav].name, params: nav[indexNav].params }
          }
        } else if (permissions.length && (!roles.length && !customerRoles.length)) {
          if (hasAnyPermission && hasAnyService) {
            return { name: nav[indexNav].name, params: nav[indexNav].params }
          }
        } else if (!permissions.length && (roles.length || customerRoles.length)) {
          if ((hasAnyRole || hasAnyCustomerRole) && hasAnyService) {
            return { name: nav[indexNav].name, params: nav[indexNav].params }
          }
        } else if (!permissions.length && !roles.length) {
          if (hasAnyService) {
            return { name: nav[indexNav].name, params: nav[indexNav].params }
          }
        }
      }
    }
  }
}

export default router
